<template>
  <div id="TableContainer">
    <div>
      <Header table="historyLogs" @search="search"/>
    </div>
    <hr>
    <div id="table">
      <HistoryLogList v-bind:historyLogs="changedLogs"/>
    </div>
  </div>
</template>

<script>
import Header from "../Header";
import HistoryLogList from "./HistoryLogList";
import axios from "axios";
import Link from "../utils/Link";
import App from "../../App";
import $ from "jquery";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import ui from "../utils/ui";
import moment from "moment/moment";

let tableHeaderPosition = [0];

export default {
  components: {HistoryLogList, Header},

  data() {
    return {
      historyLogs: [],
      lastQuery: (x) => x
    }
  },

  beforeMount() {
    this.getLogs();
  },

  mounted() {
    this.$emit("changeComponent", 'historyLogs');
    this.removeAdd();
    this.getLogs().then(() => {
      this.alignColumns();
    });
  },

  created: function () {
    window.addEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrolling);
    window.removeEventListener('resize', this.windowResize);
  },

  computed: {
    changedLogs: function () {
      let field = window.localStorage.getItem("historyLogs.field");
      let orderBy = window.localStorage.getItem("historyLogs.orderBy") === 'true';
      return this.historyLogs.filter(x =>
          this.lastQuery(x, this.isInclude)
      ).sort(
          App.methods.comparator(a => a[field], orderBy)
      );
    }
  },

  methods: {
    getLogs() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getHistoryLogsUrl(),
        {headers}
      ).then(res => {
        this.historyLogs = res.data;
        for (let i = 0; i < this.historyLogs.length; i++) {
          this.historyLogs[i].message = App.methods.getLink(this.historyLogs[i].message);
        }
      }).catch(()=> {
        this.createToast(this.$t('mainLog.logsNotFound'), "error");
      })
    },

    search(qsearch) {
      this.lastQuery = qsearch;
    },

    fieldContain(query, field) {
      if (field !== null) {
        return field.toLowerCase().indexOf(query) !== -1;
      }
    },

    dateFieldContain(query, field, dateFormat) {
      if (field !== null) {
        return moment(field).format(dateFormat).indexOf(query) !== -1;
      }
    },

    isInclude(log, query) {
      return this.fieldContain(query, log.id.toString())
          || this.fieldContain(query, log.user)
          || this.fieldContain(query, log.actionName)
          || this.fieldContain(query, log.message)
          || this.fieldContain(query, log.params)
          || this.dateFieldContain(query, log.date, 'DD.MM.YYYY HH:mm');
    },

    windowResize() {
      this.alignColumns();
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    scrolling() {
      this.alignColumns();
      ui.methods.stickHeader(tableHeaderPosition);
    },

    removeAdd() {
      $('button.newButton').css('display', 'none');
    },

    alignColumns() {
      if (this.historyLogs.length > 0) {
        let rowWidth = 0;
        rowWidth += ui.methods.copyElementWidthIfDifferent('.logCreatedTd',  'th.logCreated');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.logUserTd',     'th.logUser');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.logMessageTd',  'th.logMessage');
        ui.methods.copyElementWidthIfDifferent(rowWidth, '#tableHead');
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";
</style>