<template>
  <table>
    <thead id="tableHead">
    <tr>
      <th class="logCreated" @click="sortByField('date')">
        <div class="parent">
          <div class="left">{{ this.$t("logList.created") }}</div>
          <div class="right">
            <div class="top"><span class="fa fa-sort-up" :class="sortUp('date')"></span></div>
            <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('date')"></span></div>
          </div>
        </div>
      </th>

      <th class="logUser" @click="sortByField('user')">
        <div class="parent">
          <div class="left">{{ this.$t("logList.user") }}</div>
          <div class="right">
            <div class="top"><span class="fa fa-sort-up" :class="sortUp('user')"></span></div>
            <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('user')"></span></div>
          </div>
        </div>
      </th>

      <th class="logMessage" @click="sortByField('message')">
        <div class="parent">
          <div class="left">{{ this.$t("logList.message") }}</div>
          <div class="right">
            <div class="top"><span class="fa fa-sort-up" :class="sortUp('message')"></span></div>
            <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('message')"></span></div>
          </div>
        </div>
      </th>

    </tr>
    </thead>
    <tbody>
    <SoloHistoryLog
        ref="historyLogs"
        v-for="log of historyLogs" :key="log.id"
        v-bind:historyLog="log"
    />
    </tbody>
  </table>
</template>


<script>
import SoloHistoryLog from "./SoloHistoryLog";
import App from "../../App";

export default {
  props: ['historyLogs'],
  components: {
    SoloHistoryLog
  },

  data() {
    return {
      field: undefined,
      orderBy: false,
    }
  },

  mounted() {
    this.restoreOrders();
  },


  methods: {
    restoreOrders() {
      let field = window.localStorage.getItem("historyLogs.field");
      let order = window.localStorage.getItem("historyLogs.orderBy") === 'true';
      if (field != null) {
        this.field = field
        this.orderBy = order;
        this.sortByField(null);
      }
    },

    sortByField(field) {
      if (field != null) {
        if (Object.is(this.field, field)) {
          this.orderBy = !this.orderBy;
        } else {
          this.field = field;
          this.orderBy = true;
        }

        window.localStorage.setItem("historyLogs.field", this.field)
        window.localStorage.setItem("historyLogs.orderBy", this.orderBy)
      }

      this.historyLogs.sort(App.methods.comparator(a =>
          a[this.field], this.orderBy)
      );
    },

    sortAndOrder() {
      return this.orderBy;
    },

    equalsFields(field) {
      return this.field === field;
    },

    sortUp(field) {
      return this.equalsFields(field) ? (this.sortAndOrder() ? 'inactive' : 'active') : 'inactive';
    },

    sortDown(field) {
      return this.equalsFields(field) ? (this.sortAndOrder() ? 'active' : 'inactive') : 'inactive';
    },
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";
</style>