<template>
  <tr @click="getLog(historyLog.id)">
    <td class="logCreatedTd">{{moment(historyLog.date).format('DD.MM.YYYY HH:mm')}}</td>
    <td class="logUserTd">{{ historyLog.user }}</td>
    <td class="logMessageTd" v-html="historyLog.message"></td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  props: {
    historyLog: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // this.getLink(this.historyLog.message);
  },
  methods: {
    moment,
    onChange() {
      this.$emit('addRaw')
    },
    getLog(id) {
      this.$router.push({name: 'logById', params: {'id': id.toString()}});
    },
    getLink(item) {
      const listItem = document.querySelector(".logMessageTd");
      const newItem = document.createElement('td');
      const regexpTicket = new RegExp("<ticket#(\\d+)>");
      const regexpItem = new RegExp("<Item#(\\d+)>");
      const regexpManufacturer = new RegExp("<Manufacturer#(\\d+)>");
      const matchTicket = item.match(regexpTicket);
      const matchItem = item.match(regexpItem);
      const matchManufacturer = item.match(regexpManufacturer);
      if (matchTicket) {
        const ticketName =
            (matchTicket[0].substring(matchTicket[0].indexOf('<') + 1, matchTicket[0].indexOf('>')))
                .replace('#', ' ').replace("t", "T");
        newItem.innerHTML = item.substring(0, item.indexOf('<')) +
            '<a href="/tickets/' + matchTicket[1] + '">' + ticketName + '</a>' + item.substring(item.indexOf('>') + 1);
        listItem.parentNode.replaceChild(newItem, listItem);
      } else if (matchItem) {
        const itemName =
            (matchItem[0].substring(matchItem[0].indexOf('<') + 1, matchItem[0].indexOf('>')))
                .replace('#', ' ')
        newItem.innerHTML = item.substring(0, item.indexOf('<')) +
            '<a href="/items/' + matchItem[1] + '">' + itemName + '</a>' + item.substring(item.indexOf('>') + 1);
        listItem.parentNode.replaceChild(newItem, listItem);
      } else if (matchManufacturer) {
        const manufacturerName =
            (matchManufacturer[0].substring(matchManufacturer[0].indexOf('<') + 1,
                matchManufacturer[0].indexOf('>')))
                .replace('#', ' ').replace('WikiSteger', "Manufacturer")
        newItem.innerHTML = item.substring(0, item.indexOf('<')) +
            '<a href="/manufacturers/' + matchManufacturer[1] + '">'
            + manufacturerName + '</a>' + item.substring(item.indexOf('>') + 1);
        listItem.parentNode.replaceChild(newItem, listItem);
      }
    },
  },

}
</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

td {
  vertical-align: top;
}

.logCreatedTd {
  white-space: nowrap;
  max-width: 120px;
  width: 120px;
  //min-width: 50px;
}

.logUserTd {
  padding-left: 8px;
  padding-right: 8px;
  text-align: right;
  max-width: 250px;
  white-space: nowrap;
  overflow-wrap: anywhere;
  min-width: 64px;
}

.logMessageTd {
  overflow-wrap: anywhere;
  min-width: 64px;
  text-align: left;
}

</style>